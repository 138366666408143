import * as React from "react";
import Layout from "../components/layout";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from 'react-helmet';
import { Button } from "react-bootstrap";
import commonMessageValues from '../templates/formatjs-message-values';

const Careers = () => {
  const m = useIntl().messages;
  const intl = useIntl();
  const images = useStaticQuery(graphql`{
    file(relativePath: {eq: "job.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
  }
  `);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{m.careers}</title>
      </Helmet>
      <div className="pusher">
        <div className="ui vertical masthead center aligned " id="careerBackgroundImage">
          <div className="ui text container">
          </div>
        </div>
      </div>
      <div className={"ui fluid vertical segment container " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <div className="ui column eight wide">
          <div className="column">
            <div className="ui text container">
              <FormattedMessage id="message_careers" values={commonMessageValues}/>
            </div>
          </div>
        </div>
        <div className="ui column eight wide">
          <div className="column">
            <div className="ui raised segment">
              <div className="ui three cards">
                <div className="card">
                  <div className="image">
                    <GatsbyImage image={images.file.childImageSharp.gatsbyImageData} alt="Careers Image"/>
                  </div>
                  <div className="extra content">
                    Posted Soon.
                  </div>
                </div>
                <div className="card">
                  <div className="image">
                    <GatsbyImage image={images.file.childImageSharp.gatsbyImageData} alt="Careers Image"/>
                  </div>
                  <div className="extra content">
                    Posted Soon.
                  </div>
                </div>
                <div className="card">
                  <div className="image">
                    <GatsbyImage image={images.file.childImageSharp.gatsbyImageData} alt="Careers Image"/>
                  </div>
                  <div className="extra content">
                    Posted Soon.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="ui raised segment">
              <div className="row row-center">
                <h1 className="ui header" id="heading1"
                    style={{textTransform: 'uppercase', color: '#094d7c'}}>{m.internship_available}</h1>
              </div>
              <div className="row">
                <Button href="https://forms.gle/9rgjJJcdbnhCgwGt6" target={"_blank"} size="lg"
                        block>{m.apply_now}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Careers;